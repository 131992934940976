

.centerText{
    text-align: center;
}

@media (min-width:600px){
    .marginBox{
        margin:60px
    }
    ol {
        margin-left: 50px;
    }
}
@media (max-width:600px){
    ol {
        margin-left: 25px;
    }
}