* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

.imageAutoHeight{
    height:100%;
}
.centerPad{
    padding:10px
}
.titleHeaderText{
	background-image: -webkit-gradient( linear, left top, right bottom, color-stop(0, rgb(42, 128, 240)), color-stop(0.15, rgb(34, 174, 255)) );
	background-image: gradient( linear, left top, right bottom, color-stop(0, rgb(42, 128, 240)), color-stop(0.15, rgb(34, 174, 255)) );
	color:transparent;
	-webkit-background-clip: text;
	background-clip: text;
	text-align: center;
	font-size: 30px;
	padding:20px 0px;
}
.hrCont{
    width: 100%;
    align-items: center;
    display: flex;
}
.hrLine{
    flex-grow: 1
}
.gradient-button {
    margin: 10px;
    font-family: "Arial Black", Gadget, sans-serif;
    font-size: 20px;
    padding: 15px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: #FFF;
    box-shadow: 0 0 20px #eee;
    border-radius: 10px;
    width: 200px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    cursor: pointer;
    display: inline-block;
    border-radius: 25px;
}
.flexdiv{
    display:flex;
    justify-content: space-between;
}
.divindentleft{
    margin-left:20px;
    margin-top:10px;
    margin-bottom:20px;
}
.MuiBottomNavigationAction-root .Mui-selected {
    color: #326fa2;
}
.MuiGridListTileBar-titleWrap{
    background: transparent;
}
.MuiStepIcon-active {
    fill:#326fa2 !important;
}
.MuiStepIcon-completed{
    fill:#326fa2 !important;
}

.MuiButton-containedPrimary {
    background-color:#326fa2 !important;
}
.MuiBottomNavigationAction-wrapper{
    color:#326fa2 !important;
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
    border-color:#326fa2 !important;
}
.MuiCircularProgress-svg, .MuiIconButton-colorPrimary{
    color:#326fa2 !important;
}

.MuiGridListTileBar-titlePositionBottom{
    background: transparent !important;
}
.MuiGridListTile-tile{
    border-radius: 15px !important;
    margin:0px 10px 10px 0px;
}
.inform-list{
    flex-wrap: nowrap !important;
}
.inform-list-desktop{
    margin-bottom:10px;
}
.ais-Pagination-list{
    display: flex !important;
}
.react-cal-over{
    width:auto !important;
}
.hrSpace{
    margin:50px 0px;
}
.hrTitleSpace{
    text-align: center;
    font-size: large;
    font-weight: bold;
    margin-bottom: 5px;
}
/*desktop style*/
@media (min-width:600px){
    .hit-image{
        height: 200px !important;
        width: 200px !important;
    }
    .hit-info-container{
        margin: 25px 25px 0px 25px;
    }
    .blogBody{
        width: 75%;
        margin:auto;
        font-size: large;
    }
    .catCatSlist{
        max-width: 280px;
        margin:30px auto;
    }
    .helpInfoCards{
        margin-bottom:20px;
        border-radius:15px !important;
        max-width: 250px;
    }
    .imageSetHeight{
        height:500px;
    }
    .homepageBack{
        background: url("homepagedark.jpeg");
        background-color: #cccccc;
        text-align: center;
        font-size: xxx-large;
        color:white;
        padding:40px 40px 250px 40px;
        border-radius:30px;
        background-size: cover;
    }
    .bluePageBack{
        background-image: linear-gradient(to bottom right, #063E6E, #07679F);
        text-align: center;
        font-size: xxx-large;
        color:white;
        padding:20px 40px 110px 40px;
        border-radius:30px;
        background-size: cover;
    }
    .downloadAppContainer{
        padding:90px 130px 90px 10px;
        margin-left:-25px;
        z-index:1;
        background:transparent;
    }
    .downloadAppImage{
        display:flex;
        justify-content:right;
        margin-right: 70px;
    }
    .mw340{
        max-width: 340px;
    }
    .helpPageHead{
        text-align: center;
        font-size: xxx-large;
        color:white;
        padding:40px;
        border-radius:30px;
    }
}
/*mobile style*/
@media (max-width:600px){
    .hit-image{
        height: 170px !important;
        width: 170px !important;
    }
    .blogBody{
        width: 95%;
        margin:auto;
        font-size: large;
    }
    .catCatSlist{
        margin-bottom: 20px;
    }
    .helpInfoCards{
        margin-bottom:20px;
        border-radius:15px !important;
    }
    .imageSetHeight{
        height:360px;
    }
    .homepageBack{
        background: url("homepagedark.jpeg");
        background-color:black;
        text-align: center;
        font-size: xxx-large;
        color:white;
        padding:40px 40px 150px 40px;
        border-radius:30px;
        background-size: contain;
        background-repeat: no-repeat;
    }
    .bluePageBack{
        background-image: linear-gradient(to bottom right, #054075, #0867A0);
        text-align: center;
        font-size: xxx-large;
        color:white;
        padding:40px 40px 110px 40px;
        border-radius:30px;
    }
    .downloadAppContainer{
        padding: 50px;
        z-index:1;
        background:transparent;
        text-align: center;
    }
    .downloadAppImage{
        display:flex;
        justify-content:center;
    }
    .react-datepicker__month-container{
        width: 100% !important;
    }
    .cards { grid-template-columns: repeat(2, 1fr); }
    .helpPageHead{
        text-align: center;
        font-size: xxx-large;
        color:white;
        padding:10px;
        border-radius:30px;
        margin-bottom: 30px;
    }
    .pad5{
        padding-left: 5px !important;
        padding-right: 5px !important;
    }
}

@media (prefers-color-scheme: light) {
    body,div,p,h1,h2,a{
      background: white ;
      color: black ;
    }
    .defaultBack{
        background: white;
    }
    .inputCss{

    }
    table, td, th {
        border: 1px solid black !important;
      }
  }
@media (prefers-color-scheme: dark) {
    body,div,p,h1,h2,a,.darkBackText{
        background: black ;
      color: #FFF ;
    }
    table, td, th {
        border: 1px solid white !important;
        text-align: center;
      }
    .MuiOutlinedInput-root input{
        color: white;
        border: 1px solid white !important;
        border-radius: 10px;
    }
    
    input{
        background-color: gray;
    }
    .darkBack{
        background: black !important;
    }
    .defaultBack{
        background: black;
    }
    .buttonBackground{
        background:white !important;
    }
    .darkDivider{
        background:white !important;
        margin:0px 15px !important;
    }
    .darkBorder{
        border: 1px solid grey;
    }
    .textInputStyles{
        border:1px solid gray !important;
        color:gray !important;
        border-radius: 20px;
    }
    .react-datepicker__day{
        color: white !important;
    }
    .react-datepicker__current-month {
        color:white !important
    }
    .react-datepicker__header {
        background:black !important;
    }
    .react-datepicker{
        background:black !important;
    }
    .accordSum{
        background: black;
		border-radius: 15px;
    }
    .ais-Pagination-link{
        color:white !important
    }
  }
.helpPageTable{
    margin-bottom:100px; 
    border:1px solid grey;
    border-radius:10px;
}
.bottomNavCont{
    position:fixed;
    left:0;
    right:0;
    bottom:0;
    border-top:1px solid #C0C0C0;
    z-index: 3;
  }
.stayInformedContainer{
    height: 50px;
    width: 140px;
    font-size: 10px;
    margin:0px 20px 20px 0px;
    text-align: center;
    cursor:pointer;
}
.loginPanel{
    border-radius: 20px;
    padding:20px;
    text-align:center;
}
 .hoverImage{
     opacity: 1;
 }
 .hoverImage:hover{
     opacity: .5;
 }
 .linkDec{
    margin-bottom: 10px;
    font-size: 18px;
    text-decoration: none;
 }
 .linkDec:hover {
	color: #044075;
	transition: 200ms ease-in;
}

.bulletPoint{
    margin: 0px 20px;
}

.fb{
    display:flex;
}
.fw{
    flex-wrap: wrap;
}
.verticalMargin{
    margin: auto 0;
}
.jfr{
    justify-content: right;
}
.sb{
    justify-content: space-between;
}
.se{
    justify-content: space-evenly;
}
.ac{
    align-items: center;
}
.pad50{
    padding-top: 50px;
}
.pad5{
    padding-top: 5px;
}
.mright10{
    margin-right: 10px;
}
.mb50{
    margin-bottom: 50px;
}
.iconText{
    margin-left: 10px;
    padding-top: 2px;
    margin-right: 10px;
    cursor: pointer;
}
.pickDate{

}
.contain2{
    display: grid; grid-template-columns: repeat(auto-fit, minmax(300px, 500px)); margin-top: 30px; gap:40px;
}
.react-datepicker{
    height:'100%'
}
.hrd{
    margin:40px 0px !important;
    background:grey !important;
}
.calContain div:first-child {
    height:100%
}

.mapContainer *{
    background:none;
}
.mapHeight{
    height:500px;
}
.MuiSvgIcon-root {
    color:gray;
}

.MuiAccordion-root.Mui-expanded:last-child{
    margin-bottom: 10px !important;
}
.MuiPaper-elevation1{
    box-shadow: none !important;
}
.mycard{
        padding: 0px 1rem 0px 1rem;
        background:transparent;
        border-left: 1px solid white;
        margin-left: 20px;
        margin-bottom: 20px;
}
.whiteText * {
    color:white;
}
.react-datepicker__day--disabled,.react-datepicker__day--disabled:hover{
    color:white !important;
    background:lightgray;
}

.blogHeader{

}
.blogArtTitle{
    font-size: 25px;
    font-weight: bold;
}
.blogDate{
    color:gray;
    margin:10px 0px;
}
.readMoreButton{
    color:#326fa2;
    margin:10px 0px;
}
.blogHeaderArticle{
    border: 1px solid grey;
    border-radius: 12px;
    padding: 20px;
    margin-bottom: 20px;
}
.marketingButtonCategory{
    background: #D4F2FF !important;
    margin-bottom: 20px !important;
    width: 230px;
    height: 50px;
    border-radius: 59px !important;
    border: 2px solid #427593 !important;
    min-width: 230px !important;
}
.buttonArrow{
    margin-left: 20px;
}