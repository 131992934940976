:root {
	--primary: #fff;
}

.btn {
		padding: 8px 20px;
		border-radius: 4px;
		outline: none;
		border: none;
		cursor: pointer;
		white-space: nowrap;
}

.btn--primary {
		background-color: #326fa2;
		color: white;
}
.btn--reg {
	background: #326fa2;
    margin: auto;
    color: white;
    padding: 5px 30px;
    width: auto;
}
.btn--outline{
		background-color: #326fa2;
		color: white;
		border: 1px solid black;
}
.btn--medium {
	padding: 8px 20px;
	font-size: 18px;
}
.btn--large {
	padding: 12px 26px;
	font-size: 20px;
}
.btn--mobile {
	text-align: center;
	border-raduis: 4px;
	width:80%;
	text-decoration: none;
	font-size: 1.5rem;
	background-color: transparent;
	padding: 14px 20px;
	border: 1px solid black;
	transition: all .03s ease-out;
}
.btn--wide {
	padding: 12px 64px;
	font-size: 20px;
	    width: 100%;
}

.btn--large:hover, .btn--medium:hover, .btn--mobile:hover{
	transition: all 0.3s ease-out;
	background: white;
	color: black;
}
.btn--wide:hover {
	color: white;
	background-color: red;
	transition: all 0.2s ease-out;
}
.blue {
	background-color: #326fa2;
	color: white;
	border:none;
}
.red {
	background-color: red;
	color: white;
	border:none;
}
.primary {
	background-color: black;
	color: white;
	border:none;
}
.primary:hover {
	background-color: white;
	color: black;
	border:none;
}
.green {
	background-color: green;
	color: white;
	border:none;
}
.primary:hover {
	background-color: white;
	color: black;
	border:none;
}

.btn-link{
	display: flex;
	justify-content:center;
	align-items:center;
	text-decoration: none;
	padding: 8px 16px;
	height:100%;
	width:100%;
}